<template>
    <div>
        
    </div>
</template>

<script>
export default {
    created(){
        localStorage.setItem('page','download')
        this.$router.push('/')
    },
};
</script>
<style>
   
</style>